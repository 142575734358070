<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Notifications</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark to="/notifications/detail" class="mb-2"
        >Create</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="all"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon @click="deletePopup(item)">delete</v-icon>
        <v-icon @click="sendItem(item)">send</v-icon>
      </template>
      <template v-slot:item.type="{ item }">
        <div>
          {{
            $store.state.notifications.notificationType.find(
              (m) => m.id == item.type
            ).value
          }}
        </div>
      </template>
      <template v-slot:item.days="{ item }">
        <div v-if="item.days.length > 0">
          {{
            item.days.map(
              (m) =>
                $store.state.notifications.days.find((k) => k.id == m).value
            )
          }}
        </div>
      </template>
      <template v-slot:item.largeImage="{ item }">
        <div>
          <v-img :src="item.largeImage" max-width="200" />
        </div>
      </template>
      <template v-slot:item.smallImage="{ item }">
        <div>
          <v-img :src="item.smallImage" max-width="200" />
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          Warning
        </v-card-title>
        <v-card-text class="text-h5">
          Are you sure you want to delete?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import router from "@/router";

export default {
  data() {
    return {
      all: [],
      currentItem: {},
      dialog: false,
      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "Title", value: "title" },
        { text: "Message", value: "message" },
        { text: "Type", value: "type" },
        { text: "Chip", value: "chip" },
        { text: "Gold", value: "gold" },
        { text: "VipPoint", value: "vipPoint" },
        { text: "LargeImage", value: "largeImage" },
        { text: "SmallImage", value: "smallImage" },
        { text: "CurrentCount", value: "currentCount" },
        { text: "ExpireCount", value: "expireCount" },
        { text: "ExpireTime", value: "expireTime" },

        { text: "Days", value: "days" },
        { text: "Hour", value: "hour" },
        { text: "UserIds", value: "userIds" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async deletePopup(item) {
      this.currentItem = item;
      this.dialog = true;
    },
    async deleteItem() {
      this.dialog = false;
      await this.$store.dispatch("notifications/delete", this.currentItem.id);
      await this.initialize();
    },
    async sendItem(item) {
      await this.$store.dispatch("notifications/send", item.id);
      this.$store.dispatch("setAlert");
    },
    editItem(item) {
      router.push("/notifications/detail/" + item.id);
    },
    async initialize() {
      await this.$store.dispatch("notifications/getAll");
      this.all = this.$store.state.notifications.all;
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
